<template lang='pug'>
v-list
  v-list-group(
    v-for='x in holdingsByDateGrouped'
    :key='x[0]'
    no-action
  )
    template(
      v-slot:activator
    )
      v-list-item-content
        v-list-item-title
          | {{ x[0] }} Holdings By Date
        v-list-item-subtitle
          | {{ tickerToName(x[0]) }}
    v-list-item(
      v-for='y in x[1]'
      :key='y'
      :href='titleToUrl(y)'
    )
      v-list-item-content
        v-list-item-title
          | {{ y }}
</template>

<script>
/* eslint-disable no-unused-vars */

import fundNames from "../../fundNames.json";
import holdingsByDate from "../../holdingsByDate.json";
import fp, { flatten } from "lodash/fp";

const holdingsByDateGrouped = function ()
{
  return fp.pipe
  ([
    fp.get('holdingsByDate'),
  ])
  (this)
}

const titleToTicker = title =>
(
  fp.pipe
  ([
    fp.invokeArgs('match')([/[a-z]{4}/i]),
    fp.head,
    fp.toLower,
  ])
  (title)
)

const titleToMonthDayYear = title =>
(
  fp.pipe
  ([
    fp.invokeArgs('match')([/(\d{2})\/(\d{2})\/(\d{4})$/]),
    fp.at([1, 2, 3]),
  ])
  (title)
)

const titleToMonthDayYearString = title =>
(
  fp.pipe
  ([
    titleToMonthDayYear,
    fp.join('-'),
  ])
  (title)
)

const titleToYearMonthDayString = title =>
(
  fp.pipe
  ([
    titleToMonthDayYear,
    fp.at([2, 0, 1]),
    fp.join('/'),
  ])
  (title)
)

const titleToUrl = title =>
(
  fp.pipe
  ([
    result => `https://blog.arkinvesttrades.com/${ titleToYearMonthDayString(result) }/${ titleToTicker(result) }-${ titleToMonthDayYearString(result) }/`,
  ])
  (title)
)

const tickerToName = ticker =>
(
  fp.pipe
  ([
    fp.get(fp.placeholder, fundNames),
  ])
  (ticker)
)

export default
{
  name: 'Home',
  computed:
  {
    holdingsByDateGrouped
  },
  data()
  {
    return {
      holdingsByDate
    }
  },
  methods:
  {
    tickerToName,
    titleToUrl,
  },
}
</script>